import { useContext } from 'react';
import { createContext, useState } from 'react';
import type { FC, ReactNode } from 'react';
import _ from 'lodash';
import { IUser } from 'src/@types/user';
import { useAsync } from 'src/hooks/useAsync';
import { userAPI } from 'src/api/user';
import { IGetUsersQuery, IGetUsersResponse, IPutUserCcidsBody, ICreateUserBody } from 'src/api/@types/user';
import { useSnackbar } from 'notistack';

interface IPutUserCcids extends IPutUserCcidsBody {
  id: string;
}

interface IDeleteUser {
  id: string;
}

interface IUsersContext {
  users: IGetUsersResponse;
  getUsers: (payload: IGetUsersQuery) => Promise<IGetUsersResponse | undefined>;
  createUser: (payload: ICreateUserBody) => Promise<IUser | undefined>;
  updateUserCcids: (payload: IPutUserCcids) => Promise<IUser | undefined>;
  deleteUser: (payload: IDeleteUser) => Promise<IUser | undefined>;
  isLoadingUsers: boolean;
  isCreatingUser: boolean;
  isUpdatingUserCcids: boolean;
  isDeletingUser: boolean;
}

interface IProps {
  children?: ReactNode;
}

const UsersContext = createContext<IUsersContext>({
  users: { page: 0, totalCount: 0, data: [] },
  getUsers: () => Promise.resolve(undefined),
  createUser: () => Promise.resolve(undefined),
  updateUserCcids: () => Promise.resolve(undefined),
  deleteUser: () => Promise.resolve(undefined),
  isLoadingUsers: false,
  isCreatingUser: false,
  isUpdatingUserCcids: false,
  isDeletingUser: false
});

export const UsersProvider: FC<IProps> = ({ children }) => {
  const [users, setUsers] = useState<IGetUsersResponse>({ page: 0, totalCount: 0, data: [] });
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isLoadingUsers, doAsync: getUsers } = useAsync({
    asyncFunction: userAPI.getUsers,
    executeOnMount: false,
    onSuccess: (res) => {
      setUsers(res);
    }
  });

  const _updateUserCcids = async (payload: IPutUserCcids) => {
    const { id, ...rest } = payload;
    return await userAPI.putUserCcids(id, { ...rest });
  };

  const { isLoading: isUpdatingUserCcids, doAsync: updateUserCcids } = useAsync({
    asyncFunction: _updateUserCcids,
    executeOnMount: false,
    onSuccess: (res) => {
      const clonedUsers = _.cloneDeep(users);
      const newUsersData: IGetUsersResponse = {
        page: clonedUsers.page,
        totalCount: clonedUsers.totalCount,
        data: clonedUsers.data.map((u) => (u.id === res.id ? res : u))
      };

      setUsers(newUsersData);
      enqueueSnackbar('Accessibilités modifié', {
        variant: 'success'
      });
    }
  });

  const { isLoading: isCreatingUser, doAsync: createUser } = useAsync({
    asyncFunction: userAPI.createUser,
    executeOnMount: false,
    onSuccess: (res) => {
      const clonedUsers = _.cloneDeep(users);
      const newUsersData: IGetUsersResponse = {
        page: clonedUsers.page,
        totalCount: clonedUsers.totalCount,
        data: [...clonedUsers.data, res]
      };

      setUsers(newUsersData);
      enqueueSnackbar('Utilisateur invité. Un courriel lui sera envoyé!', {
        variant: 'success'
      });
    }
  });

  const _deleteUser = async (payload: IDeleteUser) => {
    const { id } = payload;
    return await userAPI.deleteUser(id);
  };

  const { isLoading: isDeletingUser, doAsync: deleteUser } = useAsync({
    asyncFunction: _deleteUser,
    executeOnMount: false,
    onSuccess: (res) => {
      const clonedUsers = _.cloneDeep(users);
      const newUsersData: IGetUsersResponse = {
        page: clonedUsers.page,
        totalCount: clonedUsers.totalCount,
        data: clonedUsers.data.filter((u) => u.id !== res.id)
      };

      setUsers(newUsersData);

      enqueueSnackbar('Utilisateur supprimé', {
        variant: 'success'
      });
    }
  });

  return (
    <UsersContext.Provider
      value={{
        users,
        getUsers,
        createUser,
        updateUserCcids,
        deleteUser,
        isLoadingUsers,
        isCreatingUser,
        isUpdatingUserCcids,
        isDeletingUser
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

const useUsers = (): IUsersContext => useContext(UsersContext);

export default useUsers;
