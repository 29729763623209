import { useAuth0 } from '@auth0/auth0-react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import AuthPathNav from 'src/navigation/auth.path';

interface IProps {
  children?: ReactNode;
}

const GuestGuard: FC<IProps> = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Redirect to={AuthPathNav.path()} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
