import { AppBar, Divider, Box, Toolbar, Hidden, IconButton, SvgIcon, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import type { FC } from 'react';
import { Menu } from '@mui/icons-material';
import { Account } from './Account';
import { Image } from 'src/components/Image';
import { rootAPI } from 'src/api/root';
import useConfig from 'src/context/useConfig';

interface IProps {
  onMobileNavOpen?: () => void;
  height: number;
}

const TopBar: FC<IProps> = (props) => {
  const { onMobileNavOpen, height } = props;
  const { displayName } = useConfig();

  return (
    <AppBar color="inherit" position="sticky" elevation={1}>
      <Toolbar sx={{ height }}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <Menu />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/">
            <Image src={rootAPI.MEDIA_URL('short-logo.svg')} height={50} />
          </RouterLink>
          <Typography sx={{ ml: 1 }} variant="h4">
            {displayName}
          </Typography>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export { TopBar };
