import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Container, Box } from '@mui/material';
import { TopBar } from '../@shared/TopBar';
import { NavBar } from '../@shared/NavBar';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface IProps {
  children?: ReactNode;
}

const topBarHeight = 64;
const drawerWidth = 200;

const AuthLayout: FC<IProps> = ({ children }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: (theme) => theme.palette.grey['100'] }}>
      <TopBar height={topBarHeight} onMobileNavOpen={() => setMobileNavOpen(true)} />
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box display="flex" sx={{ height: `calc(100% - ${topBarHeight + 1}px)` }}>
          <Box sx={{ position: 'fixed', height: '100%', bgcolor: 'red' }}>
            <NavBar drawerWidth={drawerWidth} onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
          </Box>
          <Box sx={{ height: '100%', width: '100%', marginLeft: { lg: drawerWidth / 8 }, py: 2 }}>
            <Container maxWidth="xl">{children}</Container>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

export default AuthLayout;
