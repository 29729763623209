import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import { SettingsProvider } from 'src/context/useSettings';
import App from 'src/App';
import { Auth0Provider } from '@auth0/auth0-react';
import { config } from 'src/config';
import 'moment/locale/fr';

ReactDOM.render(
  <Auth0Provider audience={config.AUTH0.AUDIENCE} domain={config.AUTH0.DOMAIN} clientId={config.AUTH0.CLIENT_ID} redirectUri={window.location.origin}>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </Auth0Provider>,
  document.getElementById('root')
);
