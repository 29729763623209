import publicPath from './public.path';
import errorPath from './error.path';
import authPath from './auth.path';

import { IRoute } from 'src/navigation/@types/route';
import Auth0LoadingGuard from 'src/guards/Auth0LoadingGuard';
import { ConfigProvider } from 'src/context/useConfig';

const route: IRoute = {
  path: () => '/',
  guards: [Auth0LoadingGuard],
  providers: [ConfigProvider],
  routes: [errorPath, publicPath, authPath],
  redirect: publicPath.path()
};

export default route;
