import { axios } from 'src/utils/axios';
import queryString from 'query-string';
import { ITrackerResponse, IGetTrackersResponse, IPostTrackerBody, IPutTrackerBody } from './@types/tracker';
import { cleanObject } from 'src/utils/cleanObject';

class TrackerAPI {
  async getTrackers() {
    const myURL = queryString.stringifyUrl({
      url: '/api/trackers'
    });
    const { data } = await axios.get<IGetTrackersResponse>(myURL);
    return data;
  }

  async getTracker(id: string) {
    const myURL = queryString.stringifyUrl({
      url: `/api/trackers/tracker/${id}`
    });
    const { data } = await axios.get<ITrackerResponse>(myURL);
    return data;
  }

  async createTracker(payload: IPostTrackerBody) {
    const myURL = queryString.stringifyUrl({
      url: `/api/trackers/tracker`
    });
    const cleanedBody = cleanObject(payload);
    const { data } = await axios.post<ITrackerResponse>(myURL, cleanedBody);
    return data;
  }

  async updateTracker(id: string, payload: IPutTrackerBody) {
    const myURL = queryString.stringifyUrl({
      url: `/api/trackers/tracker/${id}`
    });
    const cleanedBody = cleanObject(payload);
    const { data } = await axios.put<ITrackerResponse>(myURL, cleanedBody);
    return data;
  }

  async deleteTracker(id: string) {
    const myURL = queryString.stringifyUrl({
      url: `/api/trackers/tracker/${id}`
    });
    const { data } = await axios.delete<ITrackerResponse>(myURL);
    return data;
  }
}

const trackerAPI = new TrackerAPI();

export { trackerAPI };
