import { useRef, useState } from 'react';
import type { FC } from 'react';
import { useSnackbar } from 'notistack';
import { Avatar, Box, ButtonBase, Hidden, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Logout } from '@mui/icons-material';

const Account: FC = () => {
  const ref = useRef<any>(null);
  const { user, logout } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      logout({ returnTo: window.location.origin });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Hidden smDown>
          <Typography variant="h6" color="inherit" sx={{ paddingRight: 1 }}>
            {user?.email}
          </Typography>
        </Hidden>
        <Avatar alt="Utilisateur" src={user?.picture} />
      </Box>
      <Menu
        onClose={handleClose}
        anchorEl={ref.current}
        open={isOpen}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Deconnexion
        </MenuItem>
      </Menu>
    </>
  );
};

export { Account };
