import type { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ListItem, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { SvgIconComponent } from '@mui/icons-material';

interface IProps {
  href: string;
  Icon: SvgIconComponent;
  title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  activeMenu: {
    color: theme.palette.secondary.main
  }
}));

const NavItem: FC<IProps> = (props) => {
  const { href, Icon, title, ...rest } = props;
  const classes = useStyles();

  return (
    <ListItem
      sx={{
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
      }}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        sx={{
          color: (theme) => theme.palette.text.secondary,
          padding: '10px 8px',
          justifyContent: 'flex-start',
          textTransform: 'none',
          letterSpacing: 0,
          width: '100%',
          fontWeight: (theme) => theme.typography.fontWeightRegular,
          '&.depth-0': {
            '& $title': {
              fontWeight: (theme) => theme.typography.fontWeightMedium
            }
          }
        }}
        component={NavLink}
        activeClassName={classes.activeMenu}
        to={href}
      >
        {Icon && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: (theme) => theme.spacing(1)
            }}
          >
            <Icon fontSize="small" />
          </Box>
        )}
        <span style={{ marginRight: 'auto' }}>{title}</span>
      </Button>
    </ListItem>
  );
};

export { NavItem };
