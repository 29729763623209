import { config } from 'src/config';
import queryString from 'query-string';
import { IGetPublicInfo, MEDIA_URL } from './@types/root';
import { axios } from 'src/utils/axios';

class RootAPI {
  MEDIA_URL(file: MEDIA_URL) {
    const myURL = `${config.BASE_SERVER_URL}/api/root/public/${file}`;
    return myURL;
  }

  async getPublicInfo() {
    const mediaName: MEDIA_URL = 'info.json';
    const myURL = queryString.stringifyUrl({
      url: `/api/root/public/${mediaName}`
    });
    const { data } = await axios.get<IGetPublicInfo>(myURL);
    return data;
  }
}

const rootAPI = new RootAPI();

export { rootAPI };
