import type { IRoute } from 'src/navigation/@types/route';
import userPage from './user.page';
import GroupAdminGuard from 'src/guards/GroupAdminGuard';
import { UsersProvider } from 'src/context/useUsers';
import trackerPage from './tracker.page';
import vehiculeAndMaintenancePage from './vehicule-and-maintenance.page';

const route: IRoute = {
  path: () => '/auth/groupadmin',
  guards: [GroupAdminGuard],
  providers: [UsersProvider],
  routes: [userPage, trackerPage, vehiculeAndMaintenancePage],
  redirect: userPage.path()
};

export default route;
