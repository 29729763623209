import type { IRoute } from 'src/navigation/@types/route';
import PublicLayout from 'src/layouts/public.layout';
import notFoundPage from './notfound.page';
import underConstructionPage from './underconstruction.page';

const route: IRoute = {
  path: () => '/error',
  guards: [],
  layout: PublicLayout,
  routes: [notFoundPage, underConstructionPage],
  redirect: notFoundPage.path()
};

export default route;
