import type { IRoute } from 'src/navigation/@types/route';
import PublicLayout from 'src/layouts/public.layout';
import loginPage from './login.page';
import GuestGuard from 'src/guards/GuestGuard';

const route: IRoute = {
  path: () => '/public',
  guards: [GuestGuard],
  layout: PublicLayout,
  routes: [loginPage],
  redirect: loginPage.path()
};

export default route;
