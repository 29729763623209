import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import useSettings from 'src/context/useSettings';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { createTheme } from 'src/theme';
import navigation from 'src/navigation';

import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';

import type { FC } from 'react';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { axiosInjectBearerToken } from './utils/axios';

const App: FC = () => {
  const { settings } = useSettings();
  const { getAccessTokenSilently } = useAuth0();

  const addBearerToAxiosHandler = async () => {
    axiosInjectBearerToken(getAccessTokenSilently);
  };

  useEffect(() => {
    addBearerToAxiosHandler();
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider dense maxSnack={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <GlobalStyles />
            <ScrollReset />
            {navigation}
          </BrowserRouter>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
