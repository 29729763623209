import { useContext, useEffect, useRef, useMemo } from 'react';
import { createContext } from 'react';
import type { FC, ReactNode } from 'react';
import { ICoord } from 'src/@types/location';
import { IoProvider } from 'socket.io-react-hook';
import { useAuthenticatedIO, useSocketEvent } from 'src/hooks/useAuthenticatedIO';
import useDebounce from 'src/hooks/useDebounce';

interface ITrackerEvent {
  trackerId: string;
  driver: string;
  color: string;
  lastHeartbeatAt?: Date;
  isDriving: boolean;
  latestRideId?: string;
  latestRideDistance?: number;
  latestRideCoords?: ICoord[];
  latestRideUpdatedAt?: Date;
}

interface IRideEvent {
  rideId?: string;
  trackerId: string;
  driver: string;
  color: string;
  distance?: number;
  isDriving: boolean;
  coord?: ICoord[];
}

type TrackerIdType = string;

interface IHeartbeatEvent {
  trackerId: string;
  lastHeartbeatAt: Date;
}

type IOTracker = ITrackerEvent;

interface IIOContext {
  isIOConnected: boolean;
  ioTrackers: Map<TrackerIdType, IOTracker>;
}

interface IProps {
  children?: ReactNode;
}

const IOContext = createContext<IIOContext>({
  isIOConnected: false,
  ioTrackers: new Map()
});

export const _IOProvider: FC<IProps> = ({ children }) => {
  const { connected: isIOConnected, socket } = useAuthenticatedIO();
  const { lastMessage: init } = useSocketEvent<ITrackerEvent | undefined>(socket, 'init');
  const { lastMessage: latestRide } = useSocketEvent<IRideEvent | undefined>(socket, 'ride');
  const { lastMessage: latestHeartbeat } = useSocketEvent<IHeartbeatEvent | undefined>(socket, 'heartbeat');

  const { current: ioTrackers } = useRef<Map<TrackerIdType, IOTracker>>(new Map());

  const triggerRerender1 = useDebounce(init);
  const triggerRerender2 = useDebounce(latestRide);
  const triggerRerender3 = useDebounce(latestHeartbeat);

  useEffect(() => {
    if (init) {
      ioTrackers.set(init.trackerId, init);
    }
  }, [init]);

  useEffect(() => {
    if (latestRide) {
      const ioTracker = ioTrackers.get(latestRide.trackerId);
      ioTrackers.set(latestRide.trackerId, {
        ...ioTracker,
        trackerId: latestRide.trackerId,
        driver: latestRide.driver,
        color: latestRide.color,
        isDriving: latestRide.isDriving,
        latestRideId: latestRide.rideId,
        latestRideDistance: latestRide.distance,
        latestRideCoords: latestRide.coord,
        latestRideUpdatedAt: latestRide.coord?.[latestRide.coord.length - 1].timestamp
      });
    }
  }, [latestRide]);

  useEffect(() => {
    if (latestHeartbeat) {
      const ioTracker = ioTrackers.get(latestHeartbeat.trackerId);
      ioTracker && ioTrackers.set(latestHeartbeat.trackerId, { ...ioTracker, lastHeartbeatAt: latestHeartbeat.lastHeartbeatAt });
    }
  }, [latestHeartbeat]);

  return useMemo(
    () => (
      <IOContext.Provider
        value={{
          isIOConnected,
          ioTrackers
        }}
      >
        {children}
      </IOContext.Provider>
    ),
    [triggerRerender1, triggerRerender2, triggerRerender3]
  );
};

export const IOProvider: FC<IProps> = ({ children }) => (
  <IoProvider>
    <_IOProvider>{children}</_IOProvider>
  </IoProvider>
);

const useIO = (): IIOContext => useContext(IOContext);

export default useIO;
