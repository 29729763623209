import { useAuth0 } from '@auth0/auth0-react';
import type { FC, ReactNode } from 'react';
import LoadingAuthScreen from 'src/components/LoadingAuthScreen';

interface IProps {
  children?: ReactNode;
}

/**
 * @description Needed for auth0 to load its status (loggedin or didn't log)
 */
const Auth0LoadingGuard: FC<IProps> = ({ children }) => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingAuthScreen />;
  }

  return <>{children}</>;
};

export default Auth0LoadingGuard;
