import axios, { AxiosResponse } from 'axios';
import { config } from 'src/config';

const axiosInstance = axios.create({
  timeout: 15000,
  baseURL: config.BASE_SERVER_URL
});

const axiosInjectBearerToken = (getToken: () => Promise<string>) => {
  axiosInstance.interceptors.request.use(
    async (conf) => {
      try {
        const token = await getToken();
        if (token) {
          conf.headers['Authorization'] = `Bearer ${token}`;
        }
      } catch (err) {
        console.error(err);
      }

      return conf;
    },
    (error) => Promise.reject(error)
  );
};

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: { response: AxiosResponse; message: string }) {
    const status = error?.response?.status;
    if (status === 401) {
      //not authenticated
      // return getAccessTokenRedirect();
    }
    const errorMsg = error.response?.data?.errors?.[0]?.message || error.message;
    return Promise.reject(new Error(errorMsg));
  }
);

export { axiosInstance as axios, axiosInjectBearerToken };
