import React from 'react';
import { useSocket, useSocketEvent } from 'socket.io-react-hook';
import { useAuth0 } from '@auth0/auth0-react';
import { config } from 'src/config';

export const useAuthenticatedIO = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = React.useState<string>();

  const socket = useSocket(config.BASE_SERVER_URL, {
    enabled: !!token,
    transports: ['websocket'],
    auth: {
      authorization: `Bearer ${token}`
    }
  });

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setToken(token);
    } catch (err) {}
  };

  React.useEffect(() => {
    if (!socket.connected) getToken();
  }, [socket.connected]);

  return socket;
};

export { useSocketEvent };
