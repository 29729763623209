import type { IRoute } from 'src/navigation/@types/route';
import AuthLayout from 'src/layouts/auth.layout';
import dashboardPage from './dashboard.page';
import mapPage from './map.page';
import reportPage from './report.page';
import groupadminPath from './groupadmin.path';
import AuthGuard from 'src/guards/AuthGuard';
import { TrackersProvider } from 'src/context/useTrackers';
import { IOProvider } from 'src/context/useIO';

const route: IRoute = {
  path: () => '/auth',
  guards: [AuthGuard],
  providers: [TrackersProvider, IOProvider],
  layout: AuthLayout,
  routes: [mapPage, reportPage, groupadminPath],
  redirect: mapPage.path()
};

export default route;
