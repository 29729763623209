import { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingAuthScreen: FC = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '80vh',
        justifyContent: 'center',
        padding: (theme) => theme.spacing(3)
      }}
    >
      <Box width="100%" minWidth={100} maxWidth={300}>
        <CircularProgress size="100%" thickness={2} />
      </Box>
      <Box mt={5}>
        <Typography variant="h3">Authentication...</Typography>
      </Box>
    </Box>
  );
};

export default LoadingAuthScreen;
