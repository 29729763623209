import { useContext } from 'react';
import { createContext, useState } from 'react';
import type { FC, ReactNode } from 'react';
import _ from 'lodash';
import { ITracker } from 'src/@types/tracker';
import { useAsync } from 'src/hooks/useAsync';
import { trackerAPI } from 'src/api/tracker';
import { IPostTrackerBody, IPutTrackerBody, ITrackerResponse } from 'src/api/@types/tracker';
import { useSnackbar } from 'notistack';

interface IPutTracker extends IPutTrackerBody {
  id: string;
}

interface IGetTracker {
  id: string;
}

interface IDeleteTracker {
  id: string;
}

interface ITrackersContext {
  trackers: ITracker[];
  refreshTrackers: () => void;
  isLoadingTrackers: boolean;
  updateTracker: (payload: IPutTracker) => Promise<ITrackerResponse | undefined>;
  createTracker: (payload: IPostTrackerBody) => Promise<ITrackerResponse | undefined>;
  deleteTracker: (payload: IDeleteTracker) => Promise<ITrackerResponse | undefined>;
  getTracker: (payload: IGetTracker) => Promise<ITrackerResponse | undefined>;
  isUpdatingTracker: boolean;
  isCreatingTracker: boolean;
  isDeletingTracker: boolean;
  isSyncingTracker: boolean;
}

interface IProps {
  children?: ReactNode;
}

const TrackersContext = createContext<ITrackersContext>({
  trackers: [],
  refreshTrackers: () => {},
  isLoadingTrackers: false,
  updateTracker: () => Promise.resolve(undefined),
  createTracker: () => Promise.resolve(undefined),
  deleteTracker: () => Promise.resolve(undefined),
  getTracker: () => Promise.resolve(undefined),
  isUpdatingTracker: false,
  isCreatingTracker: false,
  isDeletingTracker: false,
  isSyncingTracker: false
});

export const TrackersProvider: FC<IProps> = ({ children }) => {
  const [trackers, setTrackers] = useState<ITracker[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isLoadingTrackers, doAsync: refreshTrackers } = useAsync({
    asyncFunction: trackerAPI.getTrackers,
    executeOnMount: true,
    onSuccess: (res) => {
      setTrackers(res.trackers);
    }
  });

  const _updateTracker = async (payload: IPutTracker) => {
    const { id, ...rest } = payload;
    return await trackerAPI.updateTracker(id, { ...rest });
  };

  const { isLoading: isUpdatingTracker, doAsync: updateTracker } = useAsync({
    asyncFunction: _updateTracker,
    executeOnMount: false,
    onSuccess: (res) => {
      const clonedTrackers = _.cloneDeep(trackers);
      const newTrackers = clonedTrackers.map((t) => {
        if (t.id === res.id) {
          const updateTracker: ITracker = {
            id: res.id,
            isActivated: res.isActivated,
            ccid: res.ccid,
            driver: res.driver,
            color: res.color,
            note: res.note,
            lastLocation: res.lastLocation,
            lastSyncAt: res.lastSyncAt,
            lastHeartbeatAt: res.lastHeartbeatAt
          };
          return updateTracker;
        }
        return t;
      });

      setTrackers(newTrackers);

      enqueueSnackbar('Trackeur modifié', {
        variant: 'success'
      });
    }
  });

  const { isLoading: isCreatingTracker, doAsync: createTracker } = useAsync({
    asyncFunction: trackerAPI.createTracker,
    executeOnMount: false,
    onSuccess: (res) => {
      const createdTracker: ITracker = {
        id: res.id,
        isActivated: res.isActivated,
        ccid: res.ccid,
        driver: res.driver,
        color: res.color,
        note: res.note,
        lastLocation: res.lastLocation,
        lastSyncAt: res.lastSyncAt,
        lastHeartbeatAt: res.lastHeartbeatAt
      };
      setTrackers((os) => [...os, createdTracker]);

      enqueueSnackbar('Trackeur ajouté', {
        variant: 'success'
      });
    }
  });

  const _deleteTracker = async (payload: IDeleteTracker) => {
    const { id } = payload;
    return await trackerAPI.deleteTracker(id);
  };

  const { isLoading: isDeletingTracker, doAsync: deleteTracker } = useAsync({
    asyncFunction: _deleteTracker,
    executeOnMount: false,
    onSuccess: (res) => {
      const deletedTracker: ITracker = {
        id: res.id,
        isActivated: res.isActivated,
        ccid: res.ccid,
        driver: res.driver,
        color: res.color,
        note: res.note,
        lastLocation: res.lastLocation,
        lastSyncAt: res.lastSyncAt,
        lastHeartbeatAt: res.lastHeartbeatAt
      };
      setTrackers((os) => os.filter((t) => t.id !== deletedTracker.id));

      enqueueSnackbar('Trackeur supprimé', {
        variant: 'success'
      });
    }
  });

  const _getTracker = async (payload: IGetTracker) => {
    const { id } = payload;
    return await trackerAPI.getTracker(id);
  };

  const { isLoading: isSyncingTracker, doAsync: getTracker } = useAsync({
    asyncFunction: _getTracker,
    executeOnMount: false,
    onSuccess: (res) => {
      const clonedTrackers = _.cloneDeep(trackers);
      const newTrackers = clonedTrackers.map((t) => {
        if (t.id === res.id) {
          const updateTracker: ITracker = {
            id: res.id,
            isActivated: res.isActivated,
            ccid: res.ccid,
            driver: res.driver,
            color: res.color,
            note: res.note,
            lastLocation: res.lastLocation,
            lastSyncAt: res.lastSyncAt,
            lastHeartbeatAt: res.lastHeartbeatAt
          };
          return updateTracker;
        }
        return t;
      });

      setTrackers(newTrackers);

      enqueueSnackbar('Position mise à jour', {
        variant: 'success'
      });
    }
  });

  return (
    <TrackersContext.Provider
      value={{
        trackers,
        isLoadingTrackers,
        refreshTrackers,
        updateTracker,
        createTracker,
        deleteTracker,
        getTracker,
        isUpdatingTracker,
        isCreatingTracker,
        isDeletingTracker,
        isSyncingTracker
      }}
    >
      {children}
    </TrackersContext.Provider>
  );
};

const useTrackers = (): ITrackersContext => useContext(TrackersContext);

export default useTrackers;
