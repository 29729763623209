import { useContext } from 'react';
import { createContext, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useAsync } from 'src/hooks/useAsync';
import { rootAPI } from 'src/api/root';

interface IConfigContext {
  displayName: string;
  isLoadingConfig: boolean;
}

interface IProps {
  children?: ReactNode;
}

const ConfigContext = createContext<IConfigContext>({
  displayName: '',
  isLoadingConfig: false
});

export const ConfigProvider: FC<IProps> = ({ children }) => {
  const [displayName, setDisplayName] = useState<string>('');

  const { isLoading: isLoadingConfig } = useAsync({
    asyncFunction: rootAPI.getPublicInfo,
    executeOnMount: true,
    onSuccess: (res) => {
      setDisplayName(res.displayName);
    }
  });

  return (
    <ConfigContext.Provider
      value={{
        displayName,
        isLoadingConfig
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

const useConfig = (): IConfigContext => useContext(ConfigContext);

export default useConfig;
