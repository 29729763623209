import { useEffect } from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Divider, Drawer, Hidden, List, ListSubheader, Paper } from '@mui/material';
import { NavItem } from './NavItem';
import { Image } from 'src/components/Image';
import { DashboardOutlined, AddLocationOutlined, MapOutlined, AssessmentOutlined, GroupOutlined, CarRepairOutlined } from '@mui/icons-material';

import dashboardPage from 'src/navigation/auth.path/dashboard.page';
import trackerPage from 'src/navigation/auth.path/groupadmin.path/tracker.page';
import mapPage from 'src/navigation/auth.path/map.page';
import reportPage from 'src/navigation/auth.path/report.page';

import userPage from 'src/navigation/auth.path/groupadmin.path/user.page';
import vehiculeAndMaintenancePage from 'src/navigation/auth.path/groupadmin.path/vehicule-and-maintenance.page';
import useAuth0Permission from 'src/hooks/useAuth0Permission';
import { rootAPI } from 'src/api/root';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  drawerWidth: number;
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile, drawerWidth }) => {
  const location = useLocation();
  const permissions = useAuth0Permission();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" width={drawerWidth} display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box py={3} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Image src={rootAPI.MEDIA_URL('short-logo.svg')} height={70} />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />

        <Box p={1}>
          <List
            subheader={
              <ListSubheader disableGutters disableSticky>
                Général
              </ListSubheader>
            }
          >
            <List disablePadding>
              {/* <NavItem title="Tableau de bord" Icon={DashboardOutlined} href={dashboardPage.path()} /> */}
              <NavItem title="Carte" Icon={MapOutlined} href={mapPage.path()} />
              <NavItem title="Rapport" Icon={AssessmentOutlined} href={reportPage.path()} />
            </List>
          </List>
          {permissions.isGroupAdmin && (
            <List
              subheader={
                <ListSubheader disableGutters disableSticky>
                  Administration
                </ListSubheader>
              }
            >
              <List disablePadding>
                <NavItem title="Traqueur" Icon={AddLocationOutlined} href={trackerPage.path()} />
                <NavItem title="Utilisateur" Icon={GroupOutlined} href={userPage.path()} />
                <NavItem title="Vehicule et entretien" Icon={CarRepairOutlined} href={vehiculeAndMaintenancePage.path()} />
              </List>
            </List>
          )}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Paper sx={{ width: drawerWidth, height: '100%' }} square>
          {content}
        </Paper>
      </Hidden>
    </>
  );
};

export { NavBar };
