import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth0Permission from 'src/hooks/useAuth0Permission';
import AuthPathNav from 'src/navigation/auth.path';

interface IProps {
  children?: ReactNode;
}

const GuestGuard: FC<IProps> = ({ children }) => {
  const { isGroupAdmin } = useAuth0Permission();

  if (!isGroupAdmin) {
    return <Redirect to={AuthPathNav.path()} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
