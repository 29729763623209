import { useAuth0 } from '@auth0/auth0-react';

interface IPermission {
  isGroupAdmin: boolean;
}

function useAuth0Permission(): IPermission {
  const { user } = useAuth0();

  const permissions: IPermission = {
    isGroupAdmin: !!user?.['https://progtrack'].isGroupAdmin
  };

  return permissions;
}

export default useAuth0Permission;
