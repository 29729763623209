const config = {
  BASE_SERVER_URL: process.env.REACT_APP_BASE_SERVER_URL!,
  AUTH0: {
    DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN!,
    CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
    AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE!
  }
};

export { config };
