import { axios } from 'src/utils/axios';
import { ICreateUserBody, IGetMeResponse, IGetUsersQuery, IGetUsersResponse, IPutUserCcidsBody } from './@types/user';
import queryString from 'query-string';
import { cleanObject } from 'src/utils/cleanObject';
import { IUser } from 'src/@types/user';

class UserAPI {
  async getMe() {
    const myURL = queryString.stringifyUrl({
      url: '/api/users/me'
    });
    const { data } = await axios.get<IGetMeResponse>(myURL);
    return data;
  }

  async getUsers(query: IGetUsersQuery) {
    const cleanedQuery = cleanObject(query);

    const myURL = queryString.stringifyUrl({
      url: '/api/users',
      query: cleanedQuery
    });
    const { data } = await axios.get<IGetUsersResponse>(myURL);
    return data;
  }

  async createUser(payload: ICreateUserBody) {
    const cleanedBody = cleanObject(payload);

    const myURL = queryString.stringifyUrl({
      url: '/api/users/user/'
    });
    const { data } = await axios.post<IUser>(myURL, cleanedBody);
    return data;
  }

  async putUserCcids(id: string, payload: IPutUserCcidsBody) {
    const cleanedBody = cleanObject(payload);

    const myURL = queryString.stringifyUrl({
      url: `/api/users/user/${id}/ccids`
    });
    const { data } = await axios.put<IUser>(myURL, cleanedBody);
    return data;
  }

  async deleteUser(id: string) {
    const myURL = queryString.stringifyUrl({
      url: `/api/users/user/${id}`
    });
    const { data } = await axios.delete<IUser>(myURL);
    return data;
  }
}

const userAPI = new UserAPI();

export { userAPI };
